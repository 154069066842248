new ResizeObserver(() => {
    let vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }).observe(document.documentElement);
document.addEventListener('DOMContentLoaded', () => {
    let scrolled = false
    document.addEventListener('scroll', () => {
        if (!scrolled) {
            scrolled = true
            window.location.assign('/sections.html')
        }
    })


})